import { request, noTimeOutReq } from '@/utils/request.js'

//获取全部学生-分页
export function getStudentListApi (pageIndex, pageSize, queryStudent) {
  return request({
    url: `/user/student/admin/user/query/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data: queryStudent,
  })
}

//获取某个学生
export function getStudentApi (studentId) {
  return request({
    url: `/user/student/admin/user/${studentId}/get`,
    method: 'post',
  })
}

//添加学生
export function addStudentApi (addStudent) {
  return request({
    url: `/admin/user/student/add`,
    method: 'post',
    data: addStudent,
  })
}

//修改学生
export function updateStudentApi (updateStudent) {
  return request({
    url: `/admin/user/student/update`,
    method: 'post',
    data: updateStudent,
  })
}

//删除学生(批量)
export function deleteStudentApi (idList) {
  return request({
    url: `/admin/user/student/delete`,
    method: 'post',
    data: idList,
  })
}

//查询学校学生的毕业年份
export function getGraduationYearListApi (orgSchoolId) {
  return request({
    url: `/user/student/admin/get/graduationYear/${orgSchoolId}`,
    method: 'post',
  })
}

//上传学生人脸识别信息
export function uploadStudentFaceApi (data) {
  return noTimeOutReq({
    url: `/admin/user/student/face/update`,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    method: 'post',
    data
  })
}

//批量上传学生图片
export function uploadStudentPic (data) {
  return request({
    url: `/admin/user/student/face/batch/update`,
    method: 'post',
    data
  })
}

//获取学生面容数据
export function getStudentFaceApi (studentId) {
  return request({
    url: `/admin/user/student/face/${studentId}/list`,
    method: 'post',
  })
}

//清除学生面容数据
export function removeStudentFaceApi (studentIdList) {
  return request({
    url: `/admin/user/student/face/delete`,
    method: 'post',
    data: studentIdList
  })
}

//清除学生面容数据
export function updateStudentFaceApi () {
  return request({
    url: `/admin/user/student/face/face/data/update`,
    method: 'post',
  })
}


