<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-circle-plus-outline"
        size="medium"
        @click="edit('add', '')"
        >添加</el-button
      >
      <!-- <el-upload
        style="display:inline-block;margin:0 10px"
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="uploadBtn"
        :show-file-list="false"
      >
        <el-button v-throttle  size="medium" type="primary" icon="el-icon-upload2">导入</el-button>
      </el-upload>-->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        icon="el-icon-upload2"
        >导入</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="downloadBtn"
        icon="el-icon-download"
        >下载导入模板</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="batchImportPic"
        icon="el-icon-upload"
        >批量导入图片</el-button
      >
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-refresh"
        size="medium"
        @click="refreshBtn"
        >更新学生人脸库</el-button
      >
      <el-button
        v-throttle
        type="danger"
        icon="el-icon-delete"
        size="medium"
        @click="batchRemove"
        >批量删除</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryStudent">
        <el-form-item label="姓名" class="formItemBoxStyle">
          <el-input
            v-model="queryStudent.queryName"
            placeholder="请输入姓名"
            @input="reloadInit"
          ></el-input>
        </el-form-item>

        <el-form-item label="学号" class="formItemBoxStyle">
          <el-input
            v-model="queryStudent.queryStudentCode"
            placeholder="请输入学号"
            @input="studentCodeChange"
          ></el-input>
        </el-form-item>

        <el-form-item label="区县" class="formItemBoxStyle">
          <el-select
            v-model="queryStudent.querySysOrgAreaId"
            placeholder="请选择区县"
            @change="orgAreaChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="area in orgAreaOptions"
              :key="area.orgId"
              :label="area.orgName"
              :value="area.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校" class="formItemBoxStyle">
          <el-select
            v-model="queryStudent.querySysOrgSchoolId"
            placeholder="请选择学校"
            @change="orgSchoolChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="school in orgSchoolOptions"
              :key="school.orgId"
              :label="school.orgName"
              :value="school.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="毕业年份" class="formItemBoxStyle">
          <el-select
            v-model="queryStudent.queryGraduationYear"
            placeholder="请选择毕业年份"
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="graduationYear in graduationYearOptions"
              :key="graduationYear"
              :label="graduationYear"
              :value="graduationYear"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="班级" class="formItemBoxStyle">
          <el-select
            v-model="queryStudent.querySysOrgSchoolClassId"
            placeholder="请选择班级"
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="orgClass in orgClassOptions"
              :key="orgClass.sysOrgSchoolClassId"
              :label="orgClass.name"
              :value="orgClass.sysOrgSchoolClassId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="证件类型" class="formItemBoxStyle">
          <el-select
            v-model="queryStudent.queryIdCardType"
            placeholder="请选择证件类型"
            @change="reloadInit"
            style="width: 210px"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="idCardType in idCardTypeOptions"
              :key="idCardType.value"
              :label="idCardType.label"
              :value="idCardType.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="证件号码" class="formItemBoxStyle">
          <el-input
            v-model="queryStudent.queryIdCard"
            placeholder="请输入证件号码"
            @input="reloadInit"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="init">查询</el-button>
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="multipleTable"
        :data="studentList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="nickName"
          label="姓名"
          show-overflow-tooltip
          width="140"
        ></el-table-column>
        <el-table-column
          prop="studentCode"
          label="学号"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolName"
          label="学校"
          show-overflow-tooltip
          width="140"
        ></el-table-column>
        <el-table-column
          prop="gender"
          width="70"
          label="性别"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gender == 0">女</span>
            <span v-else-if="scope.row.gender == 1">男</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="graduationYear"
          label="毕业年份"
          show-overflow-tooltip
          width="140"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolClassName"
          label="班级"
          show-overflow-tooltip
          width="140"
        ></el-table-column>
        <el-table-column
          prop="idCardType"
          label="证件类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.idCardType == 1">身份证</span>
            <span v-else-if="scope.row.idCardType == 2"
              >港澳居民往来内地通行证</span
            >
            <span v-else-if="scope.row.idCardType == 3"
              >台湾居民往来内地通行证</span
            >
            <span v-else-if="scope.row.idCardType == 4">护照</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="idCard"
          label="证件号码"
          show-overflow-tooltip
          width="180"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="图片"
          show-overflow-tooltip
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="medium"
              type="text"
              @click="addPicture(scope.row)"
              style="color: #67c23a"
              >添加</el-button
            >
            <span style="color: #c0c4cc">|</span>
            <el-button
              v-throttle
              size="medium"
              type="text"
              @click="lookPicture(scope.row)"
              style="color: #409eff"
              >查看</el-button
            >
            <span style="color: #c0c4cc">|</span>
            <el-button
              v-throttle
              size="medium"
              type="text"
              @click="removePicture(scope.row)"
              style="color: #f56c6c"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="edit('see', scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="success"
              icon="el-icon-edit"
              @click="edit('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="danger"
              icon="el-icon-delete-solid"
              @click="remove(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 学生基本信息弹框 -->
    <el-row>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
        @close="closed"
        v-if="dialogVisible"
      >
        <el-form
          :inline="true"
          :model="submitStudent"
          :rules="rules"
          ref="studentForm"
          :disabled="isDisabled"
          label-width="80px"
        >
          <el-form-item label="姓名" prop="nickName">
            <el-input
              v-model="submitStudent.nickName"
              class="input-content"
              autocomplete="off"
              placeholder="请输入姓名"
              @input="(e) => (submitStudent.nickName = $validForbid(e))"
              maxlength="13"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="学号" prop="studentCode">
            <el-input
              v-model="submitStudent.studentCode"
              class="input-content"
              autocomplete="off"
              placeholder="请输入学号"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="区县" class="select-content" prop="sysOrgAreaId">
            <el-select
              v-model="submitStudent.sysOrgAreaId"
              placeholder="请选择区县"
              @change="selectedAreaChange"
            >
              <el-option
                v-for="area in selectedAreaOptions"
                :key="area.orgId"
                :label="area.orgName"
                :value="area.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="学校"
            class="select-content"
            prop="sysOrgSchoolId"
          >
            <el-select
              v-model="submitStudent.sysOrgSchoolId"
              placeholder="请选择学校"
              @change="selectedSchoolChange"
            >
              <el-option
                v-for="school in selectedSchoolOptions"
                :key="school.orgId"
                :label="school.orgName"
                :value="school.orgId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="毕业年份"
            prop="graduationYear"
            class="select-content"
          >
            <el-select
              v-model="submitStudent.graduationYear"
              placeholder="请选择毕业年份"
            >
              <el-option
                v-for="graduationYear in selectedGraduationYearOptions"
                :key="graduationYear"
                :label="graduationYear"
                :value="graduationYear"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="班级"
            class="select-content"
            prop="sysOrgSchoolClassId"
          >
            <el-select
              v-model="submitStudent.sysOrgSchoolClassId"
              placeholder="请选择班级"
            >
              <el-option
                v-for="orgClass in selectedClassOptions"
                :key="orgClass.sysOrgSchoolClassId"
                :label="orgClass.name"
                :value="orgClass.sysOrgSchoolClassId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="性别" class="radio-content" prop="gender">
            <el-radio v-model="submitStudent.gender" :label="1" border
              >男</el-radio
            >
            <el-radio v-model="submitStudent.gender" :label="0" border
              >女</el-radio
            >
          </el-form-item>

          <el-form-item
            label="证件类型"
            prop="idCardType"
            class="select-content"
          >
            <el-select
              v-model="submitStudent.idCardType"
              placeholder="请选择证件类型"
            >
              <el-option
                v-for="idCardType in idCardTypeOptions"
                :key="idCardType.value"
                :label="idCardType.label"
                :value="idCardType.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="证件号码" prop="idCard">
            <el-input
              v-model="submitStudent.idCard"
              class="input-content"
              placeholder="请输入证件号码"
              autocomplete="off"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-throttle @click="cancel('studentForm')">取 消</el-button>
          <el-button
            v-throttle
            type="primary"
            @click="save('studentForm')"
            :disabled="confirmBtnDisabled"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-row>
    <!-- 上传学生图片弹框 -->
    <el-row>
      <el-dialog
        :title="pictureTitle"
        :visible.sync="dialogPicVisible"
        ref="studentPictureRef"
        width="30%"
        top="30vh"
        :before-close="handlePicClose"
      >
        <el-form ref="studentForm" :disabled="isDisabled" label-width="80px">
          <el-form-item label="图片" prop="studentPicture">
            <el-upload
              action
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadStudentFace"
            >
              <img v-if="studentPicture" :src="studentPicture" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-throttle type="danger" @click="cancelPicture"
            >关闭</el-button
          >
        </div>
      </el-dialog>
    </el-row>

    <!-- 查看学生大图 -->
    <el-row>
      <el-image-viewer
        v-if="showFace"
        :on-close="closeViewer"
        :url-list="faceList"
      ></el-image-viewer>
    </el-row>

    <el-row>
      <!-- <Camera /> -->
    </el-row>
    <el-row>
      <el-dialog
        title="批量导入图片"
        :visible.sync="batchImportOpen"
        width="55%"
        @close="batchImportClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <div>
              <el-row>
                <el-col :span="12" class="badge-box">
                  <div class="fileBlock">
                    <input
                      ref="fileuploadBatch"
                      type="file"
                      name="uploadBatch"
                      multiple="multiple"
                      @change="xmTanUploadImg"
                    />
                    <img src="./img/uploadPic.png" alt />
                    <div class="imgCenter">点击上传</div>
                  </div>
                </el-col>
                <el-col :span="12" class="badge-box">
                  <img src="./img/student_example.png" alt />
                  <div class="imgCenter">示例图片</div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="12">
            <div id="import_info_box">
              <div>
                1、图片必须为清晰的、能看清学生五官的正面图，避免模糊，光线暗
              </div>
              <div>2、单张图片大小不能超过10M，人脸尺寸不小于80*80px</div>
              <div>
                3、图片命名格式<span style="color: #f59c2c"
                  >“学生学号.文件格式后缀”</span
                >或<span style="color: #f59c2c"
                  >“学生身份证号.文件格式后缀”</span
                >，如：12345678.jpeg
              </div>
              <div>4、学生图片已存在，再次导入将覆盖</div>
            </div>
          </el-col>
        </el-row>
        <div id="error_info_box">
          <span>错误详情</span>
        </div>
        <div>
          <el-card shadow="always">
            <el-row>
              <el-table
                v-loading="errorLoading"
                border
                :data="errorList"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{
                  'text-align': 'center',
                  backgroundColor: '#dfe6ec',
                }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column
                  prop="number"
                  label="序号"
                  show-overflow-tooltip
                  width="55"
                ></el-table-column>
                <el-table-column
                  prop="imgFileName"
                  label="文件名"
                  show-overflow-tooltip
                  width="170"
                ></el-table-column>
                <el-table-column
                  prop="imgUrl"
                  label="照片"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <el-image
                      style="width: 80px; height: 80px; margin-bottom: -4px"
                      :src="scope.row.imgUrl"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="exceptionBizCode"
                  label="错误原因"
                  show-overflow-tooltip
                  width="200"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.exceptionBizCode
                        | errorReason(scope.row.exceptionMsg)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="exceptionBizCode"
                  label="处理建议"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.exceptionBizCode | errorPropose }}
                  </template>
                </el-table-column>
              </el-table>
            </el-row>

            <el-row style="margin-top: 20px">
              <el-pagination
                @size-change="handleErrorSizeChange"
                @current-change="handleErrorCurrentChange"
                :current-page.sync="errorPage.pageIndex"
                :page-sizes="[5, 10]"
                :page-size="errorPage.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="errorPage.totalSize"
              ></el-pagination>
            </el-row>
          </el-card>
        </div>
      </el-dialog>
    </el-row>

    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>
<script>
import { downloadStudent, importExcelStudent } from '@/api/importExport.js'
import {
  getStudentListApi,
  getStudentApi,
  addStudentApi,
  updateStudentApi,
  deleteStudentApi,
  getGraduationYearListApi,
  uploadStudentFaceApi,
  getStudentFaceApi,
  removeStudentFaceApi,
  uploadStudentPic,
  updateStudentFaceApi,
} from '@/api/user/studentManage.js'
import {
  getOrgAreaListApi,
  getOrgSchoolListApi,
  getOrgClassListApi,
} from '@/api/user/userManage.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Camera from './components/Camera.vue'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'studentManage',
  components: {
    ElImageViewer,
    Camera,
  },
  data() {
    return {
      queryStudent: {
        queryName: '',
        queryStudentCode: '',
        querySysOrgAreaId: '',
        querySysOrgSchoolId: '',
        queryGraduationYear: '',
        querySysOrgSchoolClassId: '',
        queryIdCardType: '',
        queryIdCard: '',
      },
      batchImportOpen: false,
      //分页
      page: {
        pageIndex: 1,
        pagSize: 10,
        totalSize: 0,
      },
      //错误分页
      errorPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      //错误数据数组(绑定表单)
      errorList: [],
      //错误数据数组(待处理)
      errorData: [],
      studentList: [],
      //开启遮罩层
      loading: false,
      errorLoading: false,
      //是否开启弹出框
      dialogVisible: false,
      //弹出框title
      title: '',
      saveText: '',
      //弹出框表单数据(提交后台)
      submitStudent: {
        nickName: '',
        studentCode: '',
        sysOrgAreaId: '',
        graduationYear: '',
        sysOrgSchoolId: '',
        sysOrgSchoolClassId: '',
        gender: 1,
        idCard: '',
        idCardType: '',
      },
      // 上传文件的列表(多个)
      uploadFiles: [],
      // 上传文件的个数
      filesLength: 0,
      uploadLodaing: false,
      //表单验证
      rules: {
        nickName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        studentCode: [
          { required: true, message: '请输入学号', trigger: 'blur' },
        ],
        sysOrgAreaId: [
          {
            required: true,
            message: '请选择区县',
            trigger: ['blur', 'change'],
          },
        ],
        graduationYear: [
          { required: true, message: '请选择毕业年份', trigger: 'blur' },
        ],
        sysOrgSchoolId: [
          {
            required: true,
            message: '请选择学校',
            trigger: ['blur', 'change'],
          },
        ],
        sysOrgSchoolClassId: [
          {
            required: true,
            message: '请选择班级',
            trigger: ['blur', 'change'],
          },
        ],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        idCardType: [
          {
            required: true,
            message: '请选择证件类型',
            trigger: ['blur', 'change'],
          },
        ],
        idCard: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
        ],
      },
      //table已选中elem
      selectedList: [],
      //筛选select dom
      orgAreaOptions: [],
      orgSchoolOptions: [],
      orgClassOptions: [],
      graduationYearOptions: [],
      idCardTypeOptions: [
        { label: '身份证', value: 1 },
        { label: '港澳居民往来内地通行证', value: 2 },
        { label: '台湾居民往来内地通行证', value: 3 },
        { label: '护照', value: 4 },
      ],
      //对话框select dom
      selectedAreaOptions: [],
      selectedSchoolOptions: [],
      selectedClassOptions: [],
      selectedGraduationYearOptions: [],
      //编辑|查看 所需obj
      student: {},
      //是否禁用弹出框表单内容
      isDisabled: false,
      //是否禁用弹出框确认按钮
      confirmBtnDisabled: false,
      pictureTitle: '',
      dialogPicVisible: false,
      studentPicture: '',
      //学生信息
      studentInfo: {},
      //需上传文件
      uploadFile: {},
      //学生人脸信息
      studentFace: {},
      //大图展示
      showFace: false,
      faceList: [],
      uploadMsg: '',
      uploadLoading: false,
    }
  },
  mounted() {
    this.init()
    this.getOrgAreaList()
    this.initGraduationYear()
  },
  filters: {
    //错误原因过滤
    errorReason(val, exceptionMsg) {
      let text = val.toLowerCase()
      switch (text) {
        case 'sql_select_not_exist':
          return `学生学号不存在`
        case 'merr_asf_ex_invalid_face_info':
          return `无效的脸部信息`
        case 'oss_size_over':
          return `单张图片大小不能超过10M`
        case 'oss_format_incorrect':
          return `上传文件格式不正确`
        default:
          return exceptionMsg
      }
    },

    //错误建议过滤
    errorPropose(val) {
      let text = val.toLowerCase()
      switch (text) {
        case 'sql_select_not_exist':
          return `请检查学号是否有误，修改后重新上传`
        case 'merr_asf_ex_invalid_face_info':
          return `检查人脸是否完整清晰`
        case 'oss_size_over':
          return `可将图片进行适当压缩再重新上传`
        case 'oss_format_incorrect':
          return `请检查文件格式`
        default:
          return `请检查上传图片`
      }
    },
  },
  methods: {
    // 导入
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      // console.log('fd', fd)
      importExcelStudent(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            // this.$message.success('导入成功');
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.init()
            this.getOrgAreaList()
            this.initGraduationYear()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            // this.$message.warning(res.msg)
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
    // 下载excel模板
    downloadBtn() {
      downloadStudent().then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    batchImportPic() {
      this.batchImportOpen = true
    },
    //初始化用户信息
    init() {
      this.loading = true
      getStudentListApi(
        this.page.pageIndex,
        this.page.pagSize,
        this.queryStudent
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.studentList = []
            this.studentList = res.data
            this.page.totalSize = 0
            this.page.totalSize = res.total
          } else {
            this.msgError(res.msg)
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //获取筛选区县列表
    getOrgAreaList() {
      getOrgAreaListApi()
        .then((res) => {
          if (res.success) {
            this.orgAreaOptions = []
            this.selectedAreaOptions = []
            res.data.childrenOrg.forEach((item) => {
              if (isEmpty(item)) {
                return
              }
              this.orgAreaOptions.push(item)
              this.selectedAreaOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    initGraduationYear() {
      this.graduationYearOptions = []
      this.selectedGraduationYearOptions = []
      this.graduationYearOptions = this.$Global.graduationYearList
      this.selectedGraduationYearOptions = this.$Global.graduationYearList
    },
    orgAreaChange(orgId) {
      this.getOrgSchoolList(orgId)
      this.queryStudent.querySysOrgSchoolId = ''
      this.queryStudent.queryGraduationYear = ''
      this.queryStudent.querySysOrgSchoolClassId = ''
      this.reloadInit()
    },
    //获取筛选学校列表
    getOrgSchoolList(orgId) {
      if (isEmpty(orgId)) {
        return
      }
      getOrgSchoolListApi(0, 1, orgId)
        .then((res) => {
          if (res.success) {
            this.orgSchoolOptions = []
            this.selectedSchoolOptions = []
            res.data.childrenOrg.forEach((item) => {
              if (isEmpty(item)) {
                return
              }
              this.orgSchoolOptions.push(item)
              this.selectedSchoolOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    orgSchoolChange(schoolId) {
      this.queryStudent.queryGraduationYear = ''
      this.queryStudent.querySysOrgSchoolClassId = ''
      this.getOrgClassList(schoolId)
      this.reloadInit()
      // this.getGraduationYearList(schoolId);
    },
    //获取筛选班级列表
    getOrgClassList(schoolId) {
      getOrgClassListApi(schoolId)
        .then((res) => {
          if (res.success) {
            this.orgClassOptions = []
            this.selectedClassOptions = []
            res.data.forEach((item) => {
              if (isEmpty(item)) {
                return
              }
              this.orgClassOptions.push(item)
              this.selectedClassOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    //获取筛选毕业年份列表
    getGraduationYearList(schoolId) {
      getGraduationYearListApi(schoolId)
        .then((res) => {
          if (res.success) {
            this.graduationYearOptions = []
            this.selectedGraduationYearOptions = []
            // res.data.forEach((item) => {
            //   if (isEmpty(item)) {
            //     return;
            //   }
            //   this.graduationYearOptions.push(item);
            //   this.selectedGraduationYearOptions.push(item);
            // });
          }
        })
        .catch(() => {})
    },

    //《弹出对话框处理》
    //获取所需学校
    selectedAreaChange(orgId) {
      this.submitStudent.sysOrgSchoolId = ''
      this.submitStudent.graduationYear = ''
      this.submitStudent.sysOrgSchoolClassId = ''
      this.getOrgSchoolList(orgId)
    },
    //获取所需年份班级
    selectedSchoolChange(schoolId) {
      this.submitStudent.graduationYear = ''
      this.submitStudent.sysOrgSchoolClassId = ''
      this.getOrgClassList(schoolId)
      // this.getGraduationYearList(schoolId);
    },
    //添加|编辑|查看用户
    edit(text, row) {
      if (!isEmpty(text)) {
        this.saveText = text
      }
      this.title = ''
      this.dialogVisible = true
      this.isDisabled = false
      this.confirmBtnDisabled = false
      if (text == 'add') {
        this.title = '添加学生'
      } else if (text == 'see') {
        this.title = '查看学生'
        this.isDisabled = true
        this.confirmBtnDisabled = true
        if (isEmpty(row)) {
          return
        }
        this.getStudent(row)
        // this.$router.push({ name: 'studentInfo', params: { id: row.studentId } });
      } else {
        this.title = '编辑学生'
        if (isEmpty(row)) {
          return
        }
        this.getStudent(row)
      }
    },
    getStudent(row) {
      if (isEmpty(row.studentId)) {
        return
      }
      const loading = this.globalLoading('正在获取用户信息...')
      getStudentApi(row.studentId)
        .then((res) => {
          loading.close()
          if (res.success) {
            if (isEmpty(res.data)) {
              return
            }
            this.submitStudent = {}
            this.submitStudent = res.data
            if (!isEmpty(res.data.sysOrgAreaId)) {
              this.getOrgSchoolList(res.data.sysOrgAreaId)
            }
            if (!isEmpty(res.data.sysOrgSchoolId)) {
              this.getOrgClassList(res.data.sysOrgSchoolId)
              // this.getGraduationYearList(res.data.sysOrgSchoolId);
            }
          } else {
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //提交后台保存数据
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitStudent.graduationYear = Number(
            this.submitStudent.graduationYear
          )
          this.submitStudent.sysOrgSchoolId = Number(
            this.submitStudent.sysOrgSchoolId
          )
          this.submitStudent.sysOrgSchoolClassId = Number(
            this.submitStudent.sysOrgSchoolClassId
          )
          this.submitStudent.idCardType = Number(this.submitStudent.idCardType)
          if (this.saveText == 'add') {
            const loading = this.globalLoading('正在添加...')
            addStudentApi(this.submitStudent)
              .then((res) => {
                loading.close()
                if (res.success) {
                  this.msgSuccess('添加成功')
                  this.clearForm(formName)
                  this.dialogVisible = false
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                loading.close()
              })
          } else {
            const loading = this.globalLoading('正在修改...')
            updateStudentApi(this.submitStudent)
              .then((res) => {
                loading.close()
                if (res.success) {
                  this.msgSuccess('修改成功')
                  this.clearForm(formName)
                  this.dialogVisible = false
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        } else {
          return false
        }
      })
    },
    //删除
    remove(row) {
      if (isEmpty(row)) {
        return
      }
      if (isEmpty(row.studentId)) {
        return
      }
      let idList = []
      idList.push(row.studentId)
      this.globalMessageBox('确认删除吗?', '提示', '确定', '取消', 'warning')
        .then(() => {
          if (idList.length <= 0) {
            return
          }
          const loading = this.globalLoading('正在删除...')
          deleteStudentApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.reloadInit()
              } else {
                this.msgError(res.msg)
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    //批量删除
    batchRemove() {
      if (this.selectedList.length <= 0) {
        this.msgWarning('请选中要删除的数据')
        return
      }
      let idList = []
      this.selectedList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.studentId)) {
          return
        }
        idList.push(item.studentId)
      })
      this.globalMessageBox(
        `确认删除${this.selectedList.length}项吗?`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          if (idList.length <= 0) {
            return
          }
          const loading = this.globalLoading('正在删除...')
          deleteStudentApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.reloadInit()
              } else {
                if (res.code != 500) {
                  this.msgWarning(`参加考试的${res.msg}`)
                }
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    nickNameChange() {
      this.reloadInit()
    },
    studentCodeChange() {
      this.reloadInit()
    },
    //刷新
    refresh() {
      this.resetQueryParam()
      this.init()
    },
    resetQueryParam() {
      this.queryStudent.queryName = ''
      this.queryStudent.queryStudentCode = ''
      this.queryStudent.querySysOrgAreaId = ''
      this.queryStudent.querySysOrgSchoolId = ''
      this.queryStudent.queryGraduationYear = ''
      this.queryStudent.querySysOrgSchoolClassId = ''
      this.queryStudent.queryIdCardType = ''
      this.queryStudent.queryIdCard = ''
    },
    reloadInit() {
      this.page.pageIndex = 1
      this.init()
    },

    //当选择项发生变化时会触发
    handleSelectionChange(val) {
      this.selectedList = []
      this.selectedList = val
    },
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.loading = true
      this.page.pageIndex = val
      this.init()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.loading = true
      this.page.pagSize = val
      this.init()
    },
    //错误详情列表
    handleErrorSizeChange(val) {
      this.errorPage.pageSize = val
      this.errorList = this.errorData.slice(
        (this.errorPage.pageIndex - 1) * this.errorPage.pageSize,
        this.errorPage.pageIndex * this.errorPage.pageSize
      )
    },
    handleErrorCurrentChange(val) {
      this.errorPage.pageIndex = val
      this.errorList = this.errorData.slice(
        (this.errorPage.pageIndex - 1) * this.errorPage.pageSize,
        this.errorPage.pageIndex * this.errorPage.pageSize
      )
    },
    // //弹出框右上角×触发
    handleClose() {
      this.dialogVisible = false
      this.isDisabled = false
      this.clearForm('studentForm')
    },
    cancel(formName) {
      this.dialogVisible = false
      this.isDisabled = false
      this.clearForm(formName)
    },
    closed() {
      this.dialogVisible = false
      this.isDisabled = false
      this.clearForm(formName)
    },
    clearForm(formName) {
      //清空表单并重置校验
      this.$refs[formName].resetFields()
      this.submitStudent = this.$options.data().submitStudent
    },
    /* 文件操作(学生图片) */
    beforeAvatarUpload(file) {
      /*   const uploadTypes = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'webp', 'apng'];
        const fileType = file.name.replace(/.+\./, '');
        if (uploadTypes.indexOf(fileType.toLowerCase()) === -1) {
          this.msgError('文件格式错误,请重新上传!');
          return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.msgError('上传头像图片大小不能超过 2MB!');
          return;
        } */
      if (!isEmpty(file)) {
        this.uploadFile = {}
        this.uploadFile = file
      }
    },
    handleAvatarSuccess(res, file, fileList) {},
    addPicture(row) {
      this.dialogPicVisible = true
      this.pictureTitle = '添加学生图片'
      if (!isEmpty(row)) {
        this.studentInfo = {}
        this.studentInfo = row
      }
    },
    //上传学生人脸识别信息
    uploadStudentFace() {
      let fd = new FormData()
      fd.append('file', this.uploadFile)
      fd.append('studentId', this.studentInfo.studentId)
      fd.append('verify', 1)
      const loading = this.globalLoading('正在上传图片,请稍后...')
      uploadStudentFaceApi(fd)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('上传成功')
            this.init()
            this.studentPicture = ''
            this.studentPicture = this.$imageAddress(res.data.faceImgUrl)
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    lookPicture(row) {
      this.getStudentFace(row.studentId)
    },
    //获取学生面容数据
    getStudentFace(studentId) {
      if (isEmpty(studentId)) {
        return
      }
      const loading = this.globalLoading('正在获取学生面容,请稍后...')
      getStudentFaceApi(studentId)
        .then((res) => {
          loading.close()
          if (res.success) {
            if (res.data.length <= 0) {
              this.msgError('请先上传图片')
              return
            }
            this.studentFace = {}
            this.studentFace = res.data[0]
            this.faceList = []
            this.faceList.push(this.$imageAddress(this.studentFace.faceImgUrl))
            this.showFace = true
          } else {
            this.msgError('获取面容失败')
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    closeViewer() {
      this.faceList = []
      this.showFace = false
    },
    removePicture(row) {
      this.removeStudentFace(row)
    },
    removeStudentFace(row) {
      if (isEmpty(row.faceImgUrl)) {
        this.msgError('暂无可删除的图片')
        return
      }
      this.globalMessageBox('确认删除吗?', '提示', '确定', '取消', 'warning')
        .then(() => {
          let studentIdList = []
          if (!isEmpty(row.studentId)) {
            studentIdList.push(row.studentId)
          }
          const loading = this.globalLoading('正在删除图片,请稍后...')
          removeStudentFaceApi(studentIdList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.init()
              } else {
                this.msgError(res.msg)
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },

    xmTanUploadImg() {
      this.filesLength = this.$refs.fileuploadBatch.files.length
      var filesList = this.$refs.fileuploadBatch.files
      // var fl = obj.files.length;
      // var flag = false;
      var fileData = new FormData()
      for (var i = 0; i < this.filesLength; i++) {
        // console.log(filesList, 'filessss');
        var file = filesList[i]
        // 表单对象，用于将文件传到后台
        fileData.append('files', file)
      }
      fileData.append('verify', 0)
      //避免第二次选择自动触发请求
      if (this.$refs.fileuploadBatch.files.length !== 0) {
        this.errorLoading = true
        uploadStudentPic(fileData).then((res) => {
          let { success, data } = res
          console.log(success, data.total, 'resssssssssss')
          if (success) {
            this.errorData = []
            data.forEach((item, index) => {
              //处理图片url
              item.imgUrl = this.$imageAddress(item.imgUrl)
              item.number = index + 1
              this.errorData.push(item)
            })
            //分页和loading处理
            this.errorList = this.errorData.slice(
              (this.errorPage.pageIndex - 1) * this.errorPage.pageSize,
              this.errorPage.pageIndex * this.errorPage.pageSize
            )
            this.errorPage.totalSize = res.total
            this.errorLoading = false
            this.init()
            this.getOrgAreaList()
            this.initGraduationYear()
            //返回错误、成功提示
            if (this.errorList.length !== 0) {
              this.$message.error('请检查错误详情')
            } else {
              this.$message.success('上传成功')
            }
          }
          this.$refs.fileuploadBatch.value = ''
        })
      }
    },

    /*  // 修改当前文件列表长度
     changeFileLength (file, fileList) {
       this.filesLength = fileList.length
     },
 
     // 用户点击上传调用
     async upload () {
       // 触发上传 调用配置 :http-request="uploadFile"
       // 即触发 uploadFile函数
       await this.$refs.upload.submit();
     },
 
     // 该函数还是会被调用多次
     uploadFunc (param) {
       // 将文件加入需要上传的文件列表
       // console.log(param, 'param');
       this.uploadFiles.push(param.file)
       // 当uploadFiles长度等于用户需要上传的文件数时进行上传
       if (this.uploadFiles.length == this.filesLength) {
         // 创建FormData上传
         let fileData = new FormData()
         // 将全部文件添加至FormData中
         this.uploadFiles.forEach(file => {
           fileData.append('files', file)
         })
         // 将附加信息添加至FormData
         fileData.append('verify', 0)
         uploadStudentPic(fileData).then(res => {
           let { success, data } = res
           console.log(success, data, 'resssssssssss');
           if (success) {
             this.$refs.upload.clearFiles()
             this.uploadFiles = []
             // this.errorPage.totalSize = data.total
             let errorData = []
             data.forEach(item => {
               //处理图片url
               item.imgUrl = this.$imageAddress(item.imgUrl)
               errorData.push(item)
             });
             this.errorList = errorData
             this.$message.success('上传成功')
           }
 
         })
       }
     }, */

    //关闭批量导入图片弹窗
    batchImportClose() {
      this.batchImportOpen = false
      this.errorList = []
      this.errorPage.totalSize = 0
    },

    handlePicClose() {
      this.clearPictureForm()
    },
    cancelPicture() {
      this.clearPictureForm()
    },
    clearPictureForm() {
      this.dialogPicVisible = false
      this.studentPicture = ''
    },
    refreshBtn() {
      updateStudentFaceApi()
        .then((res) => {
          if (res.success) {
            this.$message.success('完成更新')
          } else {
            this.$message.success(res.msg)
          }
        })
        .catch((err) => {})
    },
  },
}
</script>
<style lang="scss">
.input-content,
.select-content > .el-form-item__content > .el-select,
.radio-content > .el-form-item__content,
.treeBox {
  width: 250px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 300px;
  line-height: 300px;
  text-align: center;
}

.avatar {
  width: 300px;
  height: 300px;
  display: block;
}

.badge-box {
  border: 2px dotted #c0c4cc;
  height: 180px;
}

.badge-box:last-child {
  border-left: 0;
  padding: 0 5px;
}

.badge-box:last-child img {
  width: 100%;
  width: 100px;
  height: 125px;
  margin-left: 73px;
  margin-top: 15px;
}

.badge-box .imgCenter {
  text-align: center;
}

#import_info_box {
  height: 133px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#import_info_box > div {
  margin-bottom: 10px;
}

#import_info_box > div:last-child {
  margin-bottom: 0px;
}

#error_info_box {
  margin: 8px 0;
}

#error_info_box > span {
  font-size: 16px;
}

.badge-box:first-child .el-upload {
  width: 100%;
}

.badge-box:first-child .el-upload-dragger {
  width: 100%;
  height: 100%;
  border: 0;
}

.alldialog {
  .el-dialog__wrapper {
    pointer-events: none !important;
  }

  .el-dialog {
    pointer-events: auto !important;
  }
}

.fileBlock input {
  color: red;
  height: 130px;
  opacity: 0;
  width: 240px;
}

.fileBlock img {
  position: absolute;
  width: 80px;
  height: 60px;
  left: 17%;
  top: 28%;
  pointer-events: none;
}
</style>
